import { logger } from '@/utils/logger'
import { appSessionStorage, localStorageKey } from './storage'
import router from '@/routes/router'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { i18n } from '@/utils/i18n'
import { isIOSSafari } from '@/utils/parseUserAgents'
import { MiscThanksReasons } from '@/utils/thanksPageHelpers'
import { fireAndForget } from '@/utils/fireAndForget'
import { notaryPagePaths } from '@/routes/notaryRoutes'

// See axios/lib/core/enhanceError.js for fields
interface AxiosError extends Error {
    request?: any
    response?: any
    code?: string
    isAxiosError?: boolean
}

const enhancedMessage = (message: string, enhancement?: string): string => {
    return `${message}${enhancement ? ` (${enhancement})` : ''}`
}

/**
 * Depending on the error, either redirects the application to the 'Thanks' page (and
 * returns an empty string) or returns an error message to display to the applicant.
 */
const NotaryApiErrorHandler = function (error: AxiosError): string {
    const message = error?.message
    if (error?.response?.status === undefined || error?.response?.status === 0) {
        logger.warn(enhancedMessage(`Network error`, message), null /* event */, error)
        return i18n.tc('global.errors.generic')
    }

    // Can add other errors
    switch (error.response.status) {
        case 401:
            logger.log(`Logging out because of invalid credentials (http 401)`)
            appSessionStorage.setItem(localStorageKey.clearStorageOnNavigation, 'true')
            fireAndForget(
                () => router.replace(notaryPagePaths.NOTARY_LOGIN),
                (e) => logger.warn(`Failed to redirect to notary login page for authorization! ${e}`)
            )
            break
        case 400:
            logger.error(`Bad request`, null /* event */, error)
            return i18n.tc('global.errors.badRequest')
        default:
            logger.error(enhancedMessage(`API error`, message), null /* event */, error)
            return message
    }

    return ''
}

/**
 * Depending on the error, either redirects the application to the 'Thanks' page (and
 * returns an empty string) or returns an error message to display to the applicant.
 */
const ApiErrorHandler = function (error: AxiosError, message?: string): string {
    if (error?.response?.status === undefined || error?.response?.status === 0) {
        logger.warn(enhancedMessage(`Network error`, message), null /* event */, error)
        return i18n.tc('global.errors.generic')
    }

    // Can add other errors
    switch (error.response.status) {
        case 401:
            if (!appSessionStorage.getItem(localStorageKey.jwtTokens)) {
                if (isIOSSafari()) {
                    logger.warn(enhancedMessage(`Got 401 error, and in private browsing. Redirecting...`, message), null /* event */, error)
                    fireAndForget(
                        () => router.replace({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.privateBrowsing } }),
                        (e) => logger.warn(`Failed to redirect to thanks page for private browsing! ${e}`)
                    )
                    break
                }
            }
            appSessionStorage.removeItem(localStorageKey.jwtTokens)
            logger.warn(enhancedMessage(`Got 401 error, not in private browsing. Redirecting...`, message), null /* event */, error)
            fireAndForget(
                () => router.replace({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.authorization } }),
                (e) => logger.warn(`Failed to redirect to thanks page for authorization! ${e}`)
            )
            break
        case 400:
            logger.error(`Bad request`, null /* event */, error)
            return i18n.tc('global.errors.badRequest')
        default:
            logger.error(enhancedMessage(`API error`, message), null /* event */, error)
            fireAndForget(
                () =>
                    router.replace({
                        path: sharedPagePaths.THANKS,
                        query: {
                            reason: MiscThanksReasons.review,
                        },
                    }),
                (e) => logger.warn(`Failed to redirect to thanks page for manual review! ${e}`)
            )
    }

    return ''
}

export { ApiErrorHandler, NotaryApiErrorHandler }
