import { RouteConfig } from 'vue-router'

const routeName = 'origination'

const pageNames = {
    VERIFY_IDENTITY: 'identity',
    IDENTITY_QUESTIONS: 'identity_questions',
    NOTARY_SCHEDULING: 'notary_scheduling',
    EXPERIAN_FROZEN: 'credit_report_frozen',
    SCHEDULING_CONFIRMATION: 'scheduling_confirmation',
    NOTARY_KBA_START: 'kba',
    NOTARY_KBA_QUESTIONS: 'kba_questions',
    REMOTE_NOTARIZATION_SESSION: 'notary_session',
    NOTARY_AV_PERMISSIONS: 'av_permissions',
    WELCOME: 'welcome',
}

export const originationPagePaths = {
    VERIFY_IDENTITY: `/${routeName}/${pageNames.VERIFY_IDENTITY}`,
    IDENTITY_QUESTIONS: `/${routeName}/${pageNames.IDENTITY_QUESTIONS}`,
    SCHEDULING_CONFIRMATION: `/${routeName}/${pageNames.SCHEDULING_CONFIRMATION}`,
    NOTARY_SCHEDULING: `/${routeName}/${pageNames.NOTARY_SCHEDULING}`,
    EXPERIAN_FROZEN: `/${routeName}/${pageNames.EXPERIAN_FROZEN}`,
    NOTARY_AV_PERMISSIONS: `/${routeName}/${pageNames.NOTARY_AV_PERMISSIONS}`,
    NOTARY_KBA_START: `/${routeName}/${pageNames.NOTARY_KBA_START}`,
    NOTARY_KBA_QUESTIONS: `/${routeName}/${pageNames.NOTARY_KBA_QUESTIONS}`,
    REMOTE_NOTARIZATION_SESSION: `/${routeName}/${pageNames.REMOTE_NOTARIZATION_SESSION}`,
    WELCOME: `/${routeName}/${pageNames.WELCOME}`,
}

const dataCollectionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.VERIFY_IDENTITY,
        component: () => import('@/pages/origination/VerifyIdentity.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.IDENTITY_QUESTIONS,
        component: () => import('@/pages/origination/IdentityQuestions.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.EXPERIAN_FROZEN,
        component: () => import('@/pages/origination/ExperianFrozen.vue'),
        meta: { transitionName: 'slide' },
    },
]

const completionRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.WELCOME,
        component: () => import('@/pages/origination/Welcome.vue'),
        meta: { transitionName: 'slide' },
    },
]

const schedulingRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.NOTARY_SCHEDULING,
        component: () => import('@/pages/origination/NotaryScheduling.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.NOTARY_AV_PERMISSIONS,
        component: () => import('@/pages/origination/NotaryAVPermissions.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.SCHEDULING_CONFIRMATION,
        component: () => import('@/pages/origination/SchedulingConfirmation.vue'),
        meta: { transitionName: 'slide' },
    },
]

const remoteNotarizationRoutes: RouteConfig[] = [
    {
        path: originationPagePaths.NOTARY_KBA_START,
        component: () => import('@/pages/origination/VerifyIdentity.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.NOTARY_KBA_QUESTIONS,
        component: () => import('@/pages/origination/IdentityQuestions.vue'),
        meta: { transitionName: 'slide' },
    },
    {
        path: originationPagePaths.REMOTE_NOTARIZATION_SESSION,
        component: () => import('@/pages/origination/ApplicantNotarySession.vue'),
        meta: { transitionName: 'slide' },
    },
]

export const originationRoutes: RouteConfig[] = [...dataCollectionRoutes, ...completionRoutes, ...schedulingRoutes, ...remoteNotarizationRoutes]
