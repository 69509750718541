// Polyfills Vue.js router to avoid throwing on navigation errors
import Router from 'vue-router'
import { logger } from '@/utils/logger'
;['push', 'replace'].forEach((method) => {
    const originalMethod = Router.prototype[method]
    Router.prototype[method] = function m(location) {
        return originalMethod.call(this, location).catch((error) => {
            // The these errors are innocuous issues that we don't want to throw for
            // https://router.vuejs.org/guide/advanced/navigation-failures.html#navigationfailuretype
            logger.warn('Navigation failure', null, error)
        })
    }
})
