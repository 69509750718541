/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 */
import assert from 'assert'
import { inspect } from '@/utils/inspect'

const localStorageKey = {
    basicInfo: 'basicInfo',
    sessionId: 'sessionId',
    inviteCode: 'inviteCode',
    currentFlow: 'currentFlow',
    locale: 'locale',
    applicantNotaryAssignmentId: 'applicantNotaryAssignmentId',
    applicantId: 'applicantId',
    loanApplicationId: 'loanApplicationId',
    experimentName: 'experimentName',
    phoneNumber: 'phoneNumber',
    phoneHash: 'phoneHash',
    plaidAccounts: 'plaidAccounts',
    plaidPublicToken: 'plaidPublicToken',
    institutionInfo: 'institutionInfo',
    normalizedPhoneNumber: 'normalizedPhoneNumber',
    prettyPhoneNumber: 'prettyPhoneNumber',
    jwtTokens: 'jwtTokens',
    coApplicantJwtTokens: 'coApplicantJwtTokens',
    applicationData: 'applicationData',
    waitListCompletionState: 'waitListCompletionState',
    originationCompletionState: 'originationCompletionState',
    statedIncome: 'statedIncome',
    coApplicantStatedIncome: 'coApplicantStatedIncome',
    sessionAccessJWT: 'sessionAccessJWT',
    prequalifiedAmount: 'prequalifiedAmount',
    firstName: 'firstName',
    lastName: 'lastName',
    coApplicantFirstName: 'coApplicantFirstName',
    coApplicantLastName: 'coApplicantLastName',
    employer: 'employer',
    jobTitle: 'jobTitle',
    previousPage: 'previousPage',
    utmTags: 'utmTags',
    sessionIdCallRunning: 'sessionIdCallRunning',
    startPagePath: 'startPagePath',
    clearStorageOnNavigation: 'clearStorageOnNavigation',
    waitlistPosition: 'waitlistPosition',
    identityQA: 'identityQA',
    propertyLtvMean: 'propertyLtvMean',
    propertyTotalEquityMean: 'propertyTotalEquityMean',
    logRocketInitialized: 'logRocketInitialized',
    logRocketUrl: 'logRocketUrl',
    performExperianSoftPull: 'performExperianSoftPull',
    notaryAccessJWT: 'notaryAccessJWT',
    homeOwnershipStatus: 'homeOwnershipStatus',
    inviteCodeRequired: 'inviteCodeRequired',
    accessJWT: 'accessJWT',
    loanApplicationNumber: 'loanApplicationNumber',
    creditOffer: 'creditOffer',
    preQualificationOffer: 'preQualificationOffer',
    visitedBankConnectBefore: 'visitedBankConnectBefore',
    incomeVerificationCompleted: 'incomeVerificationCompleted',
    isNotPreQualified: 'isNotPreQualified',
    preQualificationFailureCode: 'preQualificationFailureCode',
    notaryStartPagePath: 'notaryStartPagePath',
    confirmedTimeSlot: 'confirmedTimeSlot',
    completedAccounts: 'completedAccounts',
    notaryId: 'notaryId',
    notaryName: 'notaryName',
    payStubInfo1: 'payStubInfo1',
    payStubInfo2: 'payStubInfo2',
    alreadySubmittedHMDA: 'alreadySubmittedHMDA',
    dmOrigin: 'dmOrigin',
    systemFraudFlags: 'systemFraudFlags', // Stores a JSON array of strings
    alreadySubmittedFraudFlag: 'alreadySubmittedFraudFlag',
    // this overrides the timeout/polling value for cypress tests atm due to them being http/1.1 https://github.com/cypress-io/cypress/issues/3708
    httpTimeout: 'httpTimeout',
    pollInterval: 'pollInterval',
    experimentOverrides: 'experimentOverrides',
}

export class AppStorage {
    storage: Storage | undefined
    inMemoryStorage: Map<string, string>

    constructor(storage?: Storage) {
        this.storage = storage
        this.inMemoryStorage = new Map()
    }

    // window.storage only accepts string
    // https://developer.mozilla.org/en-US/docs/Web/API/Storage/setItem
    setItem(key: string, value: string) {
        assert(typeof key === 'string', `AppStorage.setItem key must be a string`)
        assert(typeof value === 'string', `AppStorage.setItem value for ${key} must be a string`)
        this.inMemoryStorage.set(key, value)
        try {
            this.storage?.setItem(key, value)
        } catch (e) {
            console.log('Browser storage unavailable, failed with error:', e)
        }
    }

    /**
     * Value can be of any type, if its not a string user must provide a transformer function which returns a string
     * @param key {string}
     * @param value {any}
     * @param transformer {function}
     */
    setItemIfPresent(key: string, value: any, transformer?: (param: any) => string) {
        if (typeof value === 'undefined' || value === null) {
            console.log(`Skipping setting key ${key}`)
            return
        }

        assert(typeof value === 'string' || transformer, 'Value is not of type string and no transformer was provided')

        const finalValue = transformer ? transformer(value) : value
        return this.setItem(key, finalValue)
    }

    getItem(key: string): string {
        let value = this.inMemoryStorage.get(key)
        if (typeof value === 'undefined') {
            value = this.storage?.getItem(key) as string
            // Keep in sync inMemoryStorage
            if (value !== null) {
                console.log(`Value ${value} for ${key} found in browser storage but not in memory. Setting in memory`)
                this.inMemoryStorage.set(key, value)
            }
        }

        return value
    }

    removeItem(key: string) {
        console.log(`Removing '${key}' from storage`)
        this.inMemoryStorage.delete(key)
        this.storage?.removeItem(key)
    }

    clear() {
        console.log('Clearing storage contents')
        this.inMemoryStorage.clear()
        this.storage?.clear()
    }

    /** @param exceptedKeyList string[] The keys we want to keep */
    clearWithException(exceptedKeyList: string[]) {
        console.log(`Clearing storage contents except: ${exceptedKeyList.join(', ')}`)
        const clearKeys: string[] = []
        Object.keys(this.getAll()).forEach((key) => {
            if (!exceptedKeyList.includes(key)) {
                clearKeys.push(key)
            }
        })
        clearKeys.forEach((key) => this.removeItem(key))
    }

    getAll(): { [key: string]: string } {
        const inMemoryStorageObj = Object.fromEntries(this.inMemoryStorage)
        const browserStorageObj = Object.assign({}, this.storage)
        console.log(`In memory storage contents: ${inspect(inMemoryStorageObj)}`)
        console.log(`Browser storage contents: ${inspect(browserStorageObj)}`)
        return Object.assign(browserStorageObj, inMemoryStorageObj)
    }
}

/**
 * Creating the instances of storage.
 */
const appLocalStorage = new AppStorage(window.localStorage)
const appSessionStorage = new AppStorage(window.sessionStorage)

if (process.env.NODE_ENV === 'development') {
    // Enable direct access for dev purposes
    // @ts-ignore
    window.appLocalStorage = appLocalStorage
    // @ts-ignore
    window.appSessionStorage = appSessionStorage
}

export { appLocalStorage, appSessionStorage, localStorageKey }
