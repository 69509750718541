export enum RouteOption {
    identityQuestions = 'identityQuestions',
    scheduling = 'scheduling',
    experianFrozen = 'experianFrozen',
    awaitingManualIncomeVerification = 'awaitingManualIncomeVerification',
}

interface FlowInfo {
    parent: string | null
    flow: string[] | null
}

const getNormalizedPath = (path: string): string => {
    return path.split('/').filter(Boolean).join('/').toLowerCase()
}
export const checkPathsMatch = (pathOne: string, pathTwo: string): boolean => {
    return getNormalizedPath(pathOne) === getNormalizedPath(pathTwo)
}

export const tryGetNextFlowPath = (currPath: string, mainFlow: string[], optionalFlowMap: Map<string, Map<string, string[]>>): string | null => {
    const currFlowInfo: FlowInfo = getCurrentFlowInfo(currPath, mainFlow, optionalFlowMap)
    if (!currFlowInfo.flow) {
        return null
    }

    const nextIdx = currFlowInfo.flow.findIndex((path) => checkPathsMatch(path, currPath)) + 1
    if (nextIdx < currFlowInfo.flow.length) {
        return currFlowInfo.flow[nextIdx]
    }
    return currFlowInfo.parent
}

const getCurrentFlowInfo = (currPath: string, mainFlow: string[], optionalFlowMap: Map<string, Map<string, string[]>>): FlowInfo => {
    if (mainFlow.findIndex((path) => checkPathsMatch(path, currPath)) >= 0) {
        return { parent: null, flow: mainFlow }
    }
    for (const [parent, optionalFlowGroup] of optionalFlowMap.entries()) {
        for (const optionalFlow of optionalFlowGroup.values()) {
            if (optionalFlow.findIndex((path) => checkPathsMatch(path, currPath)) >= 0) {
                return { parent, flow: optionalFlow }
            }
        }
    }
    return { parent: null, flow: null }
}

export const tryGetOptionalPath = (currPath: string, optionalFlowMap: Map<string, Map<string, string[]>>, routeOption: string): string | null => {
    const optionalFlowGroup = optionalFlowMap.get(currPath)
    const optionalFlow = optionalFlowGroup?.get(routeOption)

    if (optionalFlow && optionalFlow.length > 0) {
        return optionalFlow[0]
    }
    return null
}

export const checkAreAllPathsUnique = (mainFlow: string[], optionalFlowMap: Map<string, Map<string, string[]>>): boolean => {
    const pathMap: Set<string> = new Set()
    for (const path of mainFlow) {
        if (getNormalizedPath(path) in pathMap) {
            return false
        }
        pathMap.add(getNormalizedPath(path))
    }
    for (const optionalFlowGroup of optionalFlowMap.values()) {
        for (const optionalFlow of optionalFlowGroup.values()) {
            for (const path of optionalFlow) {
                if (getNormalizedPath(path) in pathMap) {
                    return false
                }
                pathMap.add(getNormalizedPath(path))
            }
        }
    }
    return true
}
