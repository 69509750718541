/**
 * ------------------------------------------- Configuration of Flows -------------------------------------------
 *
 *       q - r - s  <- optional flow
 *       |
 *   a - b - c - d - e - f  <- main flow
 *       | /         |
 *       g - h       i - j  <- optional flow
 *                       |
 *                       k - l - m - n - o  <- optional flow
 *
 * mainFlow = [a, b, c, d, e, f]
 * Optional flows: qFlow = [q, r, s]; gFlow = [g, h]; iFlow = [i, j]; kFlow = [k, l, m, n, o]
 * Each flow is described by an array of page paths (strings) starting at index 0 and ending at the last index.
 * !!!Note that all page paths must be unique!!!!, otherwise a secondary identifier must be used for discrimination.
 *
 * optionalFlowMap = {b: {qOption: qFlow, gOption: gFlow}, c: {gOption: gFlow}, e: {iOption: iFlow}, j: {kOption: kFlow}}
 * The optional flow map describes the children of each page in terms of secondary (optional) flows.
 *
 * qOption = RouteOption.qFlowIdentifier, gOption = RouteOption.gFlowIdentifier, etc...
 * Each page (parent) may have several optional routes (children). A particular child of a page is specified
 * by referencing a RouteOption parameter (enum). See f.e. the optional routes accessible from page b.
 *
 * Once the end of an optional flow is reached, !!!the next page will be the parent of that optional flow!!!
 */

import { originationPagePaths } from '@/routes/originationRoutes'
import { checkAreAllPathsUnique, checkPathsMatch, RouteOption, tryGetNextFlowPath, tryGetOptionalPath } from '@/flow/flowUtility'
import { authPagePaths } from '@/routes/authRoutes'
import { sharedPagePaths } from '@/routes/sharedRoutes'

export const originationEntryPagePaths: string[] = [authPagePaths.RETURN_TO_NOTARIZATION, authPagePaths.SECONDARY_USER_PERSONAL_INFO]

export const originationReturnPagePathPairs: string[][] = [
    [authPagePaths.RETURN_TO_NOTARIZATION, originationPagePaths.NOTARY_SCHEDULING],
    [authPagePaths.SECONDARY_USER_PERSONAL_INFO, originationPagePaths.NOTARY_SCHEDULING],
]

export const originationBackGuardPagePaths: string[] = [
    sharedPagePaths.NOTARY_REQUIRES_SAFARI,
    originationPagePaths.IDENTITY_QUESTIONS,
    originationPagePaths.REMOTE_NOTARIZATION_SESSION,
    originationPagePaths.SCHEDULING_CONFIRMATION,
    sharedPagePaths.NOTARY_REQUIRES_AV,
    originationPagePaths.WELCOME,
]

const originationFlow: string[] = [
    originationPagePaths.NOTARY_SCHEDULING,
    originationPagePaths.NOTARY_KBA_START,
    originationPagePaths.NOTARY_AV_PERMISSIONS,
    originationPagePaths.REMOTE_NOTARIZATION_SESSION,
    originationPagePaths.WELCOME,
]

const experianFrozenFlow: string[] = [originationPagePaths.EXPERIAN_FROZEN]

const schedulingFlow: string[] = [originationPagePaths.SCHEDULING_CONFIRMATION]
const schedulingFlowGroup: Map<string, string[]> = new Map([
    [RouteOption.scheduling, schedulingFlow],
    [RouteOption.experianFrozen, experianFrozenFlow],
])

const identityQuestionsFlow: string[] = [originationPagePaths.IDENTITY_QUESTIONS]
const identityFlowGroup: Map<string, string[]> = new Map([[RouteOption.identityQuestions, identityQuestionsFlow]])

const kbaQuestionsFlow: string[] = [originationPagePaths.NOTARY_KBA_QUESTIONS]
const kbaFlowGroup: Map<string, string[]> = new Map([[RouteOption.identityQuestions, kbaQuestionsFlow]])

const optionalFlowMap: Map<string, Map<string, string[]>> = new Map([
    [originationPagePaths.VERIFY_IDENTITY, identityFlowGroup],
    [originationPagePaths.NOTARY_KBA_START, kbaFlowGroup],
    [originationPagePaths.NOTARY_SCHEDULING, schedulingFlowGroup],
])

// Could be a unit test
if (!checkAreAllPathsUnique(originationFlow, optionalFlowMap)) {
    throw new Error('origination page paths not unique!')
}

export const getOriginationNextRouteWithCurrentPath = (currentPath: string, routeOption?: RouteOption): string | null => {
    if (originationEntryPagePaths.findIndex((path) => checkPathsMatch(path, currentPath)) >= 0) {
        return originationFlow[0]
    }

    for (const [path, nextPath] of originationReturnPagePathPairs) {
        if (checkPathsMatch(path, currentPath)) {
            return nextPath
        }
    }

    let nextPath: string | null
    if (routeOption) {
        nextPath = tryGetOptionalPath(currentPath, optionalFlowMap, routeOption)
    } else {
        nextPath = tryGetNextFlowPath(currentPath, originationFlow, optionalFlowMap)
    }
    return nextPath
}
