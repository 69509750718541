import { logger } from '@/utils/logger'

export default {
    methods: {
        toThousands(val) {
            return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        toFormattedUSD(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)
        },
        toFormattedUSDNoCents(number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
            }).format(number)
        },
        formatMoney(value) {
            return '$' + parseInt(value).toLocaleString()
        },
        formatApr(value) {
            return `${parseFloat(String(value * 100)).toFixed(2)}%`
        },
        // use lodash?
        capitalize: (value) => {
            if (!value) return ''

            const ret = value.charAt(0).toUpperCase() + value.substring(1).toLowerCase()
            logger.info(`value: ${value} camcelCase: ${ret}`)
            return ret
        },
    },
}
