import 'url-search-params-polyfill'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { httpClient } from '@/utils/http-client'
import LogRocket from 'logrocket'
import { fireAndForget } from '@/utils/fireAndForget'

export const setLocalMetadata = () => {
    if (document.referrer) {
        const parser = new URL(document.referrer)
        window.logEvent('system_aven_init', { referrer: document.referrer, referring_domain: parser.hostname })
    }
}

export const submitLogRocketSessionUrl = async (sessionId, logRocketSessionUrl) => {
    try {
        await httpClient.post('/ana/logRocketUrl', { sessionId, logRocketSessionUrl })
    } catch (error) {
        logger.error(`submission failed, LogRocket sessionUrl, ${logRocketSessionUrl}`, null /* event */, error)
    }
}

export const logRocketIdentify = () => {
    try {
        const loanApplicationId = appSessionStorage.getItem(localStorageKey.loanApplicationId)
        const applicantId = appSessionStorage.getItem(localStorageKey.applicantId)
        const applicantNotaryAssignmentId = appSessionStorage.getItem(localStorageKey.applicantNotaryAssignmentId)
        const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
        LogRocket.identify(sessionId, {
            loanApplicationId: loanApplicationId || undefined,
            applicantId: applicantId || undefined,
            applicantNotaryAssigmentId: applicantNotaryAssignmentId || undefined,
        })
    } catch (error) {
        logger.info(`LogRocket failed to identify: ${error}`)
    }
}

export const initLogRocket = () => {
    const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
    const rootHostname = process.env.VUE_APP_AVEN_URL.replace(/^https?:\/\//gi, '')
    if (['production', 'localprod', 'uat'].includes(process.env.VUE_APP_NODE_ENV)) {
        logger.info(`initLogRocket with sessionId: ${sessionId}`)
        try {
            LogRocket.init(process.env.VUE_APP_LOGROCKET_ID, { rootHostname })
            logRocketIdentify()
            appSessionStorage.setItem(localStorageKey.logRocketInitialized, 'true')
            LogRocket.getSessionURL((sessionURL) => {
                appSessionStorage.setItem(localStorageKey.logRocketUrl, `${sessionURL}`)
                fireAndForget(
                    () => submitLogRocketSessionUrl(sessionId, sessionURL),
                    (e) => logger.error(`Failed to submit logrocket session url! ${e}`)
                )
            })
        } catch (error) {
            logger.info(`LogRocket failed to initialize for sessiondId: ${sessionId}`)
        }
    } else {
        appSessionStorage.setItem(localStorageKey.logRocketInitialized, 'true')
        appSessionStorage.setItem(localStorageKey.logRocketUrl, 'local dev test logrocket session url')
        fireAndForget(
            () => submitLogRocketSessionUrl(sessionId, 'local dev test logrocket session url'),
            (e) => logger.error(`Failed to submit logrocket session url! ${e}`)
        )
    }
}
